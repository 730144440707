<template>
  <v-select
    v-bind="$attrs"
    ref="select"
    v-model="value"
    :append-to-body="appendToBody"
    :clearable="clearable"
    :calculate-position="withPopper"
    :label="label"
    :placeholder="placeholder"
    :class="cssClassSelect"
  >
    <template v-if="showArrow" #open-indicator="{ attributes }">
      <span v-bind="attributes">
        <svg
          height="8"
          viewBox="0 0 12 8"
          width="12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.6521 1.80334L5.84875 6.69745L1.00002 1.84872"
            stroke="inherit"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      </span>
    </template>

    <template #option="option">
      <slot name="option" :option="option" />
    </template>

    <template #search="{ attributes, events }">
      <slot name="search" />

      <input class="vs__search" v-bind="attributes" v-on="events" />
    </template>

    <template #list-footer>
      <slot name="list-footer" />
    </template>

    <template #no-options="{ loading }">
      <!-- TODO: i18n -->
      <template v-if="loading">Поиск...</template>

      <template v-else>
        {{ noOptionsTxt }}
      </template>
    </template>
  </v-select>
</template>

<script>
import SelectWithPopperMixin from '@/helpers/mixins/SelectWithPopperMixin'
import { isArray, isEmpty, isNil, isString } from 'lodash'
import vSelect from 'vue-select'

export default {
  name: 'UiKitSelect',

  components: {
    vSelect,
  },

  mixins: [
    SelectWithPopperMixin,
  ],

  inheritAttrs: false,

  props: {
    // TODO: i18n
    noOptionsTxt: {
      type: String,
      default: 'Нет подходящих вариантов',
    },

    label: {
      type: String,
      default: 'name',
    },

    // TODO: i18n
    placeholder: {
      type: String,
      default: 'Выбор',
    },

    showArrow: {
      type: Boolean,
      default: true,
    },

    clearable: {
      type: Boolean,
      default: true,
    },

    appendToBody: {
      type: Boolean,
      default: true,
    },

    modelValue: {
      type: [
        Object,
        String,
        Array,
        Number,
      ],

      default: null,
    },
  },

  emits: [
    'update:modelValue',
  ],

  computed: {
    value: {
      get() {
        return this.modelValue
      },

      set(value) {
        this.$emit('update:modelValue', value)
      },
    },

    hasValue() {
      if (isNil(this.$attrs.value)) {
        return false
      }

      if (isString(this.$attrs.value) || isArray(this.$attrs.value)) {
        return this.$attrs.value.length > 0
      }

      return !isEmpty(this.$attrs.value)
    },

    isTaggable() {
      return this.$attrs.taggable === true || this.$attrs.taggable === ''
    },

    cssClassSelect() {
      return [
        'ui-kit-select',
        {
          'ui-kit-select--has-value': this.hasValue,
          'ui-kit-select--taggable': this.isTaggable,
        },
      ]
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/dist/vue-select.css';

:root {
  --vs-colors--lightest: rgba(60, 60, 60, 26%);
  --vs-colors--light: rgba(60, 60, 60, 50%);
  --vs-colors--dark: #333;
  --vs-colors--darkest: rgba(0, 0, 0, 15%);
  --vs-colors--selected-disabled: rgba(74, 176, 130, 50%);

  /* Search Input */
  --vs-search-input-color: var(--app-white);
  --vs-search-input-bg: var(--multiselectBackground-color);
  --vs-search-input-placeholder-color: var(--input-placeholder-color);

  /* Font */
  --vs-font-size: 1rem;
  --vs-line-height: 1.4;

  /* Disabled State */
  --vs-state-disabled-bg: var(--app-gray-600);
  --vs-state-disabled-color: var(--app-gray-300);
  --vs-state-disabled-controls-color: var(--app-gray-300);
  --vs-state-disabled-cursor: not-allowed;

  /* Borders */
  --vs-border-color: var(--multiselectBackground-color);
  --vs-border-width: 1;
  --vs-border-style: solid;
  --vs-border-radius: 4px;

  /* Actions: house the component controls */
  --vs-actions-padding: 4px 6px 0 3px;

  /* Component Controls: Clear, Open Indicator */
  --vs-controls-color: var(--multiselect-arrow-color);
  --vs-controls-size: 1;
  --vs-controls--deselect-text-shadow: 0 1px 0 #fff;

  /* Selected */
  --vs-selected-bg: var(--multiselectBackground-color);
  --vs-selected-color: var(--app-white);
  --vs-selected-border-color: var(--vs-border-color);
  --vs-selected-border-style: var(--vs-border-style);
  --vs-selected-border-width: var(--vs-border-width);

  /* Dropdown */
  --vs-dropdown-bg: var(--app-gray-700);
  --vs-dropdown-color: inherit;
  --vs-dropdown-z-index: 1010;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 300px;
  --vs-dropdown-box-shadow: 0 3px 6px 0 var(--vs-colors--darkest);

  /* Options */
  --vs-dropdown-option-bg: #000;
  --vs-dropdown-option-color: var(--vs-dropdown-color);
  --vs-dropdown-option-padding: 10px 20px;

  /* Active State */
  --vs-dropdown-option--active-bg: #5897fb;
  --vs-dropdown-option--active-color: #fff;

  /* Deselect State */
  --vs-dropdown-option--deselect-bg: #fb5858;
  --vs-dropdown-option--deselect-color: #fff;

  /* Transitions */
  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 150ms;
}

.vs__dropdown-option {
  white-space: normal;

  &--selected {
    font-weight: 700;
    color: var(--app-blue);
    background: transparent;
  }
}

.ui-kit-select {
  width: 100%;

  &.vs--disabled {
    .vs__selected {
      color: var(--vs-state-disabled-color);
    }
  }

  &:not(.vs--disabled) {
    .vs__dropdown-toggle {
      background-color: var(--vs-search-input-bg);
    }
  }

  .vs {
    &__dropdown-toggle {
      min-height: 48px;
      font-size: var(--vs-font-size);
    }

    &__actions {
      margin-right: 8px;
    }

    &__selected-options {
      padding-left: 8px;
      align-items: center;
      overflow: hidden;
    }

    &__selected {
      --vs-selected-bg: #41b883;

      display: block;
      padding: 4px 0 4px 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
    }

    &__deselect {
      width: 22px;
      margin-left: 8px;
      fill: #fff;
    }

    &__spinner {
      border-left-color: var(--app-white);
    }
  }

  &.vs--disabled:not(.vs--single) {
    .vs {
      &__selected {
        background-color: var(--vs-colors--selected-disabled);
      }

      &__deselect {
        cursor: var(--vs-state-disabled-cursor);
      }
    }
  }
}
</style>
